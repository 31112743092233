import "./index.css";
import React from "react";
import { Link } from "react-router-dom";

class Footer extends React.Component {
  render() {
    return (
      <footer>
        <nav
          className={"mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 " + (!this.props.hideSeparator ? " border-t border-gray-200 " : "")}
          aria-label="Top"
        >
          <div className="mt-6 flex flex-col w-full items-center justify-between pt-6 pb-20 lg:border-none md:flex-row space-y-5 md:space-y-0">
            <div className="flex items-center">
              <p className="text-base">© 2025 Surge Networks Inc.</p>
            </div>
            <div className="flex items-center md:ml-12">
              <div className="flex space-x-4 md:space-x-10 md:order-2">
                <a
                  href="https://enterprise.nssurge.com"
                  className="text-base font-medium text-gray-500 hover:text-gray-900"
                >
                  Enterprise
                </a>
                <a
                  href="https://community.nssurge.com/t/official"
                  className="text-base font-medium text-gray-500 hover:text-gray-900"
                >
                  Community
                </a>
                <a
                  href="mailto:support@nssurge.com"
                  className="text-base font-medium text-gray-500 hover:text-gray-900"
                >
                  Contact
                </a>
                <a
                  href="https://twitter.com/SurgeDebugger"
                  className="text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">Twitter</span>
                  <svg
                    className="h-6 w-6"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </nav>
      </footer>
    );
  }
}

export default Footer;
