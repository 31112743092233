import './index.css'
import React from 'react'
import Header from '../Header'
import Footer from '../Footer'
import {parseQuery, checkEmail, API_BASE} from '../util'
import {openStripe} from '../util/stripe'
import {get, post} from 'superagent'
const {Stripe} = window

class Payment extends React.Component {
  state = {
    buttonEnabled: true,
    method: 'fp'
  }

  componentDidMount() {
    const query = parseQuery(window.location.search)

    if (query.email) {
      query.emailSet = true
    }
    if (!query.product) {
      query.product = 'surge-mac-1'
    }
    this.setState(query)
  }

  handleInputChange = (event) => {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value
    })
  }

  paymentMethodDidChange = (event) => {
    this.setState({method: event.target.value})
  }

  pay (e, method) {
    e.preventDefault()
    const email = checkEmail(this.state.email);
    if (!email) {
      return false
    }
    const {product } = this.state
    get(API_BASE + '/payment/precheck?email=' + encodeURIComponent(email)).end((err, res) => {
      if (err) {
        alert('Request failed. Please try again. Error ' + err.message)
        this.setState({buttonEnabled: true})
        return
      }
      const data = res.body
      if (data.options.indexOf(product) === -1) {
        alert('The item is not available for your account. Please check upgrade options');
        this.setState({buttonEnabled: true})

        this.props.history.push('/buy_now?upgrade_email=' + encodeURIComponent(email));
        return
      }

      if (method === 'alipay') {
        post(API_BASE + '/payment/stripe-alipay-intent').send({
          sku: product,
          email: email,
        }).end((err, res) => {
          if (err) {
            alert('Request failed. Please try again. Error ' + err.message)
            this.setState({buttonEnabled: true})
            return
          }
          if (res.body.message) {
            alert(res.body.message)
            this.setState({buttonEnabled: true})
            return
          }

          const stripe = Stripe(res.body.keyPublishable);

          stripe.confirmAlipayPayment(res.body.clientSecret,
            {
              // Return URL where the customer should be redirected after the authorization.
              return_url: `https://nssurge.com/purchase-successful?email=${email}`
            }
          ).then(function (result) {
            if (result.error) {
              console.log(result.error);
              alert(result.error.message)
              this.setState({buttonEnabled: true})
              return
            }
          });
        }
        )
      } else {
        post(API_BASE + '/payment/stripe-checkout-session').send({
          sku: product,
          email: email,
        }).end((err, res) => {
          if (err) {
            alert('Request failed. Please try again. Error ' + err.message)
            this.setState({buttonEnabled: true})
            return
          }
          if (res.body.message) {
            alert(res.body.message)
            this.setState({buttonEnabled: true})
            return
          }
    
          openStripe(data.stripeKeyPublishable, res.body.id)
        }
        )
    
      }
    })
    this.setState({buttonEnabled: false})
    return false
  }

  render() {
    if (!this.state.title) {
      return <p style={{textAlign: 'center'}}>
        Your payment request cannot be processed because JavaScript is disabled or
        hasn't properly loaded. Please <a href="/buy_now">try again</a>.<br />
        If you still encounter difficulties, please contact{' '}
        <a href="mailto:support@nssurge.com">support@nssurge.com</a> for help.
      </p>
    }

    return <React.Fragment>
      <Header />
      <section>
        <div className="mt-10 mb-20 max-w-lg relative mx-auto px-8">
          <h2 className="text-lg font-medium text-gray-900">Order summary</h2>
          <div className="mt-4 rounded-lg border border-gray-200 bg-gray-50 shadow-sm">
            <ul role="list" className="divide-y divide-gray-200">
              <div className="flex py-6 ">
                <div className="ml-6 flex flex-1 flex-col">
                  <div className="flex">
                    <h4 className="text-sm">
                      <p className="mt-1 text-sm text-gray-500">
                        Item
                      </p>

                      <p className="text-lg font-medium text-gray-700 hover:text-gray-800">
                        {this.state.title}
                      </p>
                    </h4>
                  </div>

                </div>
              </div>
            </ul>
            <dl className="space-y-6 border-t border-gray-200 py-6 px-4 sm:px-6">
              <div className="flex items-center justify-between">
                <dt className="text-base font-medium">Total</dt>
                <dd className="text-base font-medium text-gray-900">${this.state.price}</dd>
              </div>
            </dl>

            <dl className="space-y-6 border-t border-gray-200 py-6 px-4 sm:px-6">
              <div className="flex flex-col">

                <label htmlFor="email-address" className="block text-base font-medium">
                  License Email Address
                </label>

                <div className="mt-1">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    autoComplete="email"
                    className="mt-2 block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm disabled:text-gray-400"
                    disabled={this.state.emailSet} value={this.state.email || ''} onChange={this.handleInputChange}
                  />
                </div>


              </div>
            </dl>

            <div className="border-t border-gray-200 py-6 px-4 sm:px-6">
              <button
                type="submit"
                className="w-full rounded-md border border-transparent bg-black py-3 px-4 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50 disabled:opacity-50 hover:text-gray-300"
                disabled={!this.state.buttonEnabled} onClick={(e) => {
                  this.pay(e, 'alipay');
                }}
              >
                <p>Pay with Alipay</p>
              </button>

              <div className="relative my-5">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center">
                  <span className="bg-gray-50 px-2 text-sm text-gray-500">Or pay another way</span>
                </div>
              </div>

              <button
                type="submit"
                className="w-full rounded-md border border-transparent bg-indigo-600 py-3 px-4 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50 disabled:opacity-50"
                disabled={!this.state.buttonEnabled} onClick={(e) => {
                  this.pay(e);
                }}
              >
                <p>Checkout</p>
                
              </button>

            </div>
          </div>
        </div>

      </section>
      <Footer />
    </React.Fragment>
  }
}

export default Payment
