import React from 'react'
import Header from '../Header'
import Footer from '../Footer'
import * as qs from 'query-string';
import { CheckIcon } from '@heroicons/react/24/outline'
import withRouter from "../util/withRouter";

class PurchaseSuccess extends React.Component {
  render() {
    const query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

    return <React.Fragment>
      <Header />

      <div className="min-h-full max-w-4xl mx-auto bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
        <div className="mx-auto max-w-max">
          <main className="sm:flex">
            <div className="shrink-0 mx-auto flex h-24 w-24 items-center justify-center rounded-full bg-green-100">
              <CheckIcon className="h-12 w-12 text-green-600" aria-hidden="true" />
            </div>

            <div className="sm:ml-6">
              <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">Thanks for your purchase</h1>
                <div className="mt-4 text-base text-gray-500">
                  {query.email && <p>Your license email is <strong>{query.email}</strong>.</p>}
                  <p>The order is processing. You should receive an email with the license key shortly.</p>
                </div>
                <p className="mt-4 text-base text-gray-500">If you don't receive the email in 15 minutes, or the license email is incorrect, please contact billing@nssurge.com. Remember to check the junk mailbox first.</p>

              </div>
              <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                <a
                  href="/"
                  className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Go back home
                </a>
              </div>
            </div>
          </main>
        </div>
      </div>

      <Footer />
    </React.Fragment>
  }
}

export default withRouter(PurchaseSuccess);
